body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EFF4FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}